@import '../styles/variables';

// Rich text editor overrides

.btxcf.RichTextEditor p,
.HLkrr.ButtonV1 {
  font-family: $font-inter !important;
  font-weight: $font-weight-medium !important;
}

.btxcf.RichTextEditor h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-neutral1000;
}

.btxcf.RichTextEditor p,
.btxcf.RichTextEditor p .small-text,
.btxcf.RichTextEditor p .medium-text {
  font-family: $font-inter !important;
  color: $color-twilight800 !important;
}

.btxcf.RichTextEditor .bold {
  font-weight: $font-weight-bold;
}

.Card--default {
  overflow: visible !important;
}

// Alert overrides

.InLineAlert__content-title,
.InLineAlert__content-body {
  font-size: $font-xsmall !important;
  color: inherit !important;
}

.InLineAlert__content-body {
  font-family: $font-inter !important;
  font-weight: $font-weight-medium !important;
}

// Increment selector overrides

div {
  [id*='IncrementSelector'] {
    justify-content: center;
  }
}

// Progress Tracker overrides

.sc-gvAMtO.fYJfhA {
  min-height: 60px !important;
}

// Grid overrides

.Card__container {
  height: 100%;
}
.caiTgK {
  width: 100% !important;
}

.RichTextEditor ul li::marker, .RichTextEditor ol li::marker {
  color: $color-neutral1000 !important
}
